import { Card, Skeleton, Stack, Stat, StatGroup, StatLabel } from '@chakra-ui/react'
import { skipToken } from '@reduxjs/toolkit/query'

import TooltippedStatNumber from '@/components/TooltippedStatNumber'
import { useGetRunStatsByUserQuery } from '@/redux/api/runs'

import { AdminRunsList } from '@/features/admin-runs/AdminRunsList'
import { useWhoamiQuery } from '@/redux/api/workspace'

export function AdminRunsPage() {
  const { data: user, isFetching: isFetchingUser } = useWhoamiQuery()
  const currentUserSlug = user?.slug

  const { data: runsData, isFetching: isFetchingRuns } = useGetRunStatsByUserQuery(currentUserSlug ? { userSlug: currentUserSlug } : skipToken, {
    skip: !currentUserSlug
  })

  const { n_active_and_pending_runs: nActiveAndPendingRuns = 0, n_active_runs: nActiveRuns = 0, n_total_runs: nTotalRuns = 0 } = runsData || {}

  return (
    <Stack gap={4}>
      <Skeleton isLoaded={!isFetchingRuns || !isFetchingUser}>
        <StatGroup gap={4} justifyContent="start" alignItems="center">
          <Card p={4}>
            <Stat border={0}>
              <StatLabel>Total Runs</StatLabel>
              <TooltippedStatNumber value={nTotalRuns} />
            </Stat>
          </Card>
          <Card p={4}>
            <Stat border={0}>
              <StatLabel>Active Runs</StatLabel>
              <TooltippedStatNumber value={nActiveRuns} />
            </Stat>
          </Card>
          <Card p={4}>
            <Stat border={0}>
              <StatLabel>Pending Runs</StatLabel>
              <TooltippedStatNumber value={nActiveAndPendingRuns - nActiveRuns} />
            </Stat>
          </Card>
        </StatGroup>
      </Skeleton>

      <AdminRunsList />
    </Stack>
  )
}
