import { useEffect } from 'react'

import { MayhemPageLoader } from '../../components/MayhemPageLoader'

import UserService from './UserService'

import { useQuery } from '@/hooks'

export function RedirectToKeycloakRegistration() {
  const query = useQuery()
  const redirectUrl = query.get('redirect_url') || undefined

  useEffect(() => {
    UserService.redirectToRegistrationPage({ redirectUri: redirectUrl })
  }, [redirectUrl])

  return <MayhemPageLoader />
}
