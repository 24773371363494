import { Button, Checkbox, CheckboxGroup, HStack, Input, Radio, RadioGroup, Stack, Text, Tooltip, useColorMode, VStack } from '@chakra-ui/react'

import React, { RefObject, useRef } from 'react'

import { useBottomScrollListener } from 'react-bottom-scroll-listener'

import debounce from 'lodash.debounce'

import { FilterPopover } from '../FilterPopover'

/**
 * Interface for the items that will be displayed in the dropdown.
 * @typedef {Object} FilterDropdownItem
 */
export interface FilterDropdownItem {
  value: string
  id: string
  disabled?: boolean
}

/**
 * Props for the FilterDropdown component.
 * @typedef {Object} FilterDropdownProps
 */
export interface FilterDropdownProps {
  /**
   * The currently selected items.
   */
  selectedItems: FilterDropdownItem[]
  /**
   * Function to convert an item to a string for display purposes.
   * @type {function}
   * @param {FilterDropdownItem | null} item - The item to convert to a string.
   * @returns {string} The string representation of the item.
   */
  itemToString?: (item: FilterDropdownItem | null) => string
  /**
   * Function to handle when an item is selected.
   * @type {function}
   * @param {FilterDropdownItem} item - The item that was selected.
   */
  onSelectedItemsChange: (items: FilterDropdownItem[]) => void
  /**
   * Function to handle when an item is selected.
   * @type {function}
   * @param {FilterDropdownItem} item - The item that was selected.
   */
  onScrollEnd?: () => void
  /**
   * Whether the dropdown allows multiple selections.
   * @type {boolean}
   */
  multiSelect?: boolean
  /**
   * Whether the dropdown is disabled.
   * @type {boolean}
   * @default false
   */
  disabled?: boolean
  /**
   * Whether the dropdown should allow users to search for items.
   * @type {boolean}
   * @default false
   */
  allowQuery?: boolean
  /**
   * The threshold for displaying the query input.
   * @type {number}
   * @default 10
   */
  showQueryThreshold?: number
  /**
   * The maximum number of items that can be inputted.
   * @type {number}
   * @default Infinity
   */
  maxInputItems?: number
  /**
   * Function to handle when the query input changes. This function should return a promise that resolves to an array of items,
   * as well as an abort controller to cancel the request for debouncing purposes.
   */
  onQueryInputChange?: (query: string) => void
  /**
   * The list of items to display.
   */
  inputItems: FilterDropdownItem[]
  /**
   * The debounce time for the query input.
   * @type {number}
   * @default 500
   */
  debounceTime?: number
  /**
   * Whether the dropdown is waiting for query results.
   * @type {boolean}
   * @default false
   */
  isLoading?: boolean
  /**
   * Whether the dropdown received an error while querying for results.
   * @type {boolean}
   * @default false
   */
  isError?: boolean
  /**
   * Default input placeholder.
   * @type {string}
   * @default 'Filter'
   */
  defaultInputPlaceholder?: string
}

/**
 * Filter dropdown component that allows users to select from a list of items. The dropdown is controlled
 * by the parent through the `selectedItems` and `onSelectedItemChange` props. We also allow for multiple
 * select and search functionality.
 */
export function FilterDropdown(props: FilterDropdownProps) {
  const {
    allowQuery = false,
    isLoading = false,
    onQueryInputChange: query,
    inputItems,
    itemToString = (item) => item?.value,
    onSelectedItemsChange,
    multiSelect = false,
    selectedItems,
    disabled = false,
    debounceTime = 500,
    showQueryThreshold = 10,
    maxInputItems = Infinity,
    defaultInputPlaceholder = 'Filter',
    isError,
    onScrollEnd = () => {}
  } = props

  const { colorMode } = useColorMode()

  const showQueryInput = maxInputItems > showQueryThreshold

  const renderTriggerText = () => {
    if (selectedItems.length > 0) {
      if (multiSelect) {
        return (
          <>
            {itemToString(selectedItems[0])} &nbsp;
            {selectedItems.length > 1 && (
              <Tooltip
                hasArrow
                bg="gray.100"
                color="black"
                label={selectedItems
                  .slice(1)
                  .map((item) => itemToString(item))
                  .join(', ')}
                aria-label="selected items tooltip"
              >
                <Text color="brand.500"> +{selectedItems.length - 1}</Text>
              </Tooltip>
            )}
          </>
        )
      } else {
        return itemToString(selectedItems[0])
      }
    }
    return defaultInputPlaceholder
  }

  // Combine the displayed and the selected items
  const allItems = [...selectedItems, ...inputItems.filter((item) => !selectedItems.find((i) => i.id === item.id))]

  // For the radio options do not change the order of the items but show the selected item in case it is not in the list
  const inputItemsSet = new Set(inputItems.map((item) => item.id))
  const missingItems = selectedItems.filter((item) => !inputItemsSet.has(item.id))
  const inputItemsWithMissing = [...missingItems, ...inputItems]

  const handleSelectAll = () => {
    onSelectedItemsChange(inputItems.filter((item) => !item.disabled))
  }

  const handleDeselectAll = () => {
    onSelectedItemsChange([])
  }

  const debouncedFetch = useRef(
    debounce((qValue) => {
      if (query) {
        query(qValue)
      }
    }, debounceTime)
  ).current

  const handleInputValueChange = (event: React.ChangeEvent) => {
    const inputValue = (event.target as HTMLInputElement).value
    debouncedFetch(inputValue)
  }

  const handleSelectChange = (selectedItem: FilterDropdownItem) => {
    const isChecked = selectedItems.find((i) => i === selectedItem) ? true : false
    if (isChecked) {
      if (!multiSelect) {
        onSelectedItemsChange([])
      } else {
        onSelectedItemsChange([...selectedItems.filter((item) => item !== selectedItem)])
      }
    } else {
      if (!multiSelect) {
        onSelectedItemsChange([selectedItem])
      } else {
        onSelectedItemsChange([...selectedItems, selectedItem])
      }
    }
  }

  const scrollRef = useBottomScrollListener(onScrollEnd) as RefObject<HTMLDivElement>

  return (
    <FilterPopover
      isError={isError}
      isDisabled={disabled}
      isActive={selectedItems.length > 0}
      triggerButtonContents={renderTriggerText()}
      isLoading={isLoading}
    >
      {multiSelect && (
        <HStack justifyContent="space-between" marginBottom="2">
          <Button size="sm" variant="ghost" onClick={handleDeselectAll} isDisabled={selectedItems.length === 0}>
            Deselect All
          </Button>
          <Button size="sm" variant="ghost" onClick={handleSelectAll} isDisabled={selectedItems.length === inputItems.length}>
            Select All
          </Button>
        </HStack>
      )}
      <VStack justifyContent="flex-start" alignItems="flex-start">
        {!!allowQuery && showQueryInput && <Input placeholder="Search for additional..." onChange={handleInputValueChange} />}
        <VStack
          maxHeight={140}
          ref={scrollRef}
          overflowY="auto"
          alignItems="flex-start"
          justifyContent="flex-start"
          width="100%"
          css={{
            '&::-webkit-scrollbar': {
              width: '4px',
              height: '4px'
            },
            '&::-webkit-scrollbar-track': {
              width: '6px',
              background: colorMode == 'light' ? 'var(--chakra-colors-gray-100)' : 'var(--chakra-colors-gray-800)'
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '24px',
              backgroundColor: colorMode == 'light' ? 'var(--chakra-colors-gray-300)' : 'var(--chakra-colors-gray-600)'
            },
            '::-webkit-scrollbar-thumb:hover': {
              backgroundColor: colorMode == 'light' ? 'var(--chakra-colors-gray-600)' : 'var(--chakra-colors-gray-300)'
            }
          }}
        >
          {multiSelect && (
            <CheckboxGroup value={selectedItems.map((v) => v.value)}>
              {allItems.map((item) => (
                <Checkbox key={item.id} value={item.value} onChange={() => handleSelectChange(item)} isDisabled={!!item.disabled} marginLeft="1">
                  {itemToString(item)}
                </Checkbox>
              ))}
            </CheckboxGroup>
          )}
          {!multiSelect && (
            <RadioGroup
              width="100%"
              padding="1"
              value={selectedItems.at(0)?.value}
              onChange={(nextValue) => {
                const item = allItems.find((i) => i.value === nextValue)
                if (!item) {
                  return
                }
                handleSelectChange(item)
              }}
            >
              <Stack>
                {inputItemsWithMissing.map((item) => (
                  <Radio key={item.id} value={item.value} onChange={() => handleSelectChange(item)} isDisabled={!(item.disabled || true)}>
                    {itemToString(item)}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          )}
        </VStack>
      </VStack>
    </FilterPopover>
  )
}
