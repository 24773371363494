import { useEffect } from 'react'

import { getWorkspaceSettingsPageTitle } from '../../titles'

import { ApiTokenList } from './ApiTokenList'

import { useWhoamiQuery } from '@/redux/api/workspace'
import { MayhemPageLoader } from '@/components/MayhemPageLoader'

export function UserSettingsAPITokensPage() {
  const { data: user } = useWhoamiQuery()
  const currentUserSlug = user?.slug

  useEffect(() => {
    document.title = getWorkspaceSettingsPageTitle(currentUserSlug || 'Loading...', 'API Tokens')
  }, [currentUserSlug])

  if (!currentUserSlug) {
    return <MayhemPageLoader />
  }
  return <ApiTokenList userSlug={currentUserSlug} />
}
