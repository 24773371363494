import { Route, RouteProps, DefaultParams, Path, Redirect } from 'wouter'

import { MayhemPageLoader } from '../../components/MayhemPageLoader'

import UserService from './UserService'

import { useWhoamiQuery } from '@/redux/api/workspace'

export function AdminRoute<T extends DefaultParams | undefined = undefined, RoutePath extends Path = Path>(
  props: RouteProps<T, RoutePath> & { fallbackPath?: string }
): JSX.Element {
  const { data: user, isFetching } = useWhoamiQuery()

  if (isFetching) {
    return <MayhemPageLoader />
  }

  if (user && user.is_admin) {
    return <Route {...props} />
  }

  UserService.doLogin()

  return <Redirect to={props.fallbackPath || '/'} />
}
