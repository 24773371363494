import { ReactNode } from 'react'
import { Redirect, useRoute } from 'wouter'
import { skipToken } from '@reduxjs/toolkit/query'
import { useDispatch } from 'react-redux'

// Use Redux store to keep track of whether the user has been redirected to the workspace invites page
// to avoid redirecting again (otherwise user would be stuck here).
import { setDidRedirectToWorkspaceInvites } from '../auth/actions'

import { useGetUserWorkspaceInvitesQuery } from '@/redux/api/invites'
import { useAppSelector } from '@/hooks'

interface Props {
  children: ReactNode
}

/**
 * Redirects to the workspace invite page if there are any pending invites.
 * Useful if user didn't receive an email or if they were invited using an
 * admin invite (if so, the email didn't contain a link to accept the invite).
 */
export function WorkspaceInvitesRedirect({ children }: Props) {
  // Avoid redirecting if already opening a workspace invite
  const matchWorkspaceInvite = useRoute('/-/settings/join-workspace')[0]
  const didRedirect = useAppSelector((state) => state.auth.didRedirectToWorkspaceInvites)
  const dispatch = useDispatch()

  const { data: invites, isFetching } = useGetUserWorkspaceInvitesQuery(matchWorkspaceInvite || didRedirect ? skipToken : undefined)

  if (invites?.invitations?.length && !matchWorkspaceInvite && !didRedirect && !isFetching) {
    const workspaces = invites.invitations.map((invite) => invite.account_slug)
    const tokens = invites.invitations.map((invite) => invite.invitation_token)
    dispatch(setDidRedirectToWorkspaceInvites())
    return <Redirect to={`/-/settings/join-workspace?workspaces=${workspaces.join(',')}&tokens=${tokens.join(',')}`} />
  }

  return <>{children}</>
}
