import { useLocation } from 'wouter'
import {
  Alert,
  AlertIcon,
  Avatar,
  Button,
  Card,
  CardBody,
  CardHeader,
  Grid,
  GridItem,
  Heading,
  Stack,
  Text,
  useToast,
  VStack
} from '@chakra-ui/react'
import { Fragment, useEffect, useState } from 'react'
import ErrorIcon from '@material-design-icons/svg/sharp/error.svg?react'

import { Page } from '@/components/Page'
import { useQuery } from '@/hooks'
import { useAcceptAccountInviteMutation } from '@/redux/api/invites'
import { getJoinWorkspacePageTitle } from '@/titles'
import { ErrorPage } from '@/components/layout/ErrorPage'

export function JoinWorkspace() {
  useEffect(() => {
    document.title = getJoinWorkspacePageTitle()
  }, [])

  const query = useQuery()
  const [_, setLocation] = useLocation()
  const toast = useToast()

  // If coming from email, a single workspace slug is sent
  const accountSlug = query.get('workspace') || ''
  const invitationToken = query.get('token') || ''

  // If coming from <WorkspaceInvitesRedirect>, multiple workspaces are sent
  const accountSlugs = query.get('workspaces')
  const invitationTokens = query.get('tokens')

  const slugs = accountSlug ? [accountSlug] : accountSlugs ? accountSlugs.split(',') : []
  const tokens = invitationToken ? [invitationToken] : invitationTokens ? invitationTokens.split(',') : []

  const [acceptInvite] = useAcceptAccountInviteMutation()
  const [errors, setErrors] = useState({} as Record<string, string | null>)
  const [hasJoined, setHasJoined] = useState({} as Record<string, boolean>)
  const [isLoading, setIsLoading] = useState(false)

  const goToWorkspace = (workspaceSlug: string) => {
    setLocation(`/${workspaceSlug}`, { replace: true })
  }

  const handleJoin = async (workspaceSlug: string, invitationToken: string) => {
    let failure = null
    try {
      setIsLoading(true)
      await acceptInvite({ accountInvitationToken: { invitation_token: invitationToken } }).unwrap()
    } catch (error) {
      if ((error as { status: number })?.status == 409) {
        // already a part of this workspace, consider it a success
      } else if ((error as { status: number })?.status == 404) {
        failure = 'Invalid invite, unable to join workspace. Please contact the workspace owner to receive a new invite.'
      } else {
        // Pass along all other errors to the user directly.
        //  - 403 is returned when token is correct, but email is wrong
        failure = (error as { data?: { message?: string } })?.data?.message || 'Unable to join team.'
      }
    } finally {
      setIsLoading(false)
    }
    if (failure) {
      setErrors({ ...errors, [workspaceSlug]: failure })
      setHasJoined({ ...hasJoined, [workspaceSlug]: false })
    } else {
      if (slugs.length === 1) {
        // If only one workspace, redirect to it
        goToWorkspace(workspaceSlug)
      } else {
        toast({
          title: 'Invite accepted successfully',
          status: 'success',
          duration: 5000,
          isClosable: true
        })
        setErrors({ ...errors, [workspaceSlug]: null })
        setHasJoined({ ...hasJoined, [workspaceSlug]: true })
      }
    }
  }

  if (slugs.length !== tokens.length) {
    return <ErrorPage errorMessage="URL is invalid. Number of workspaces differs from number of tokens" goBackLocation="/" />
  }

  return (
    <Page>
      <Stack spacing={8}>
        <Card align="center">
          <CardHeader>
            <VStack>
              <Heading>We found your Team!</Heading>
              <Text>Join your team&apos;s workspace to collaborate with them on shared projects.</Text>
            </VStack>
          </CardHeader>
          <CardBody padding={5}>
            <Grid templateColumns="repeat(10, 1fr)" gap={8} alignItems="center">
              {slugs.map((slug, index) => {
                const token = tokens[index]
                return (
                  <Fragment key={index}>
                    <GridItem rowSpan={2} colSpan={2}></GridItem>
                    <GridItem rowSpan={2} colSpan={1}>
                      <Avatar name={slug} bg="gray.400" color="white"></Avatar>
                    </GridItem>
                    <GridItem rowSpan={2} colSpan={2}>
                      <Text>{slug}</Text>
                    </GridItem>
                    <GridItem rowSpan={2} colSpan={1}></GridItem>
                    <GridItem rowSpan={2} colSpan={2}>
                      {hasJoined[slug] ? (
                        <Button variant="ghost" onClick={() => goToWorkspace(slug)} disabled={isLoading}>
                          Go to Workspace
                        </Button>
                      ) : (
                        <Button onClick={() => handleJoin(slug, token)} disabled={isLoading}>
                          Join Workspace
                        </Button>
                      )}
                    </GridItem>
                    <GridItem rowSpan={2} colSpan={2}></GridItem>
                    {errors[slug] && (
                      <>
                        <GridItem rowSpan={3} colSpan={2}></GridItem>
                        <GridItem rowSpan={3} colSpan={6}>
                          <Alert status="error" variant="outlined">
                            <AlertIcon as={ErrorIcon} /> {errors[slug]}
                          </Alert>
                        </GridItem>
                        <GridItem rowSpan={3} colSpan={2}></GridItem>
                      </>
                    )}
                  </Fragment>
                )
              })}
            </Grid>
          </CardBody>
        </Card>
      </Stack>
    </Page>
  )
}
