export const RECEIVE_JWT = 'auth/RECEIVE_JWT'
export const HAS_SENT_LOGIN_TRACKING_EVENT = 'auth/HAS_SENT_LOGIN_TRACKING_EVENT'
export const DID_REDIRECT_TO_WORKSPACE_INVITES = 'auth/DID_REDIRECT_TO_WORKSPACE_INVITES'

export type ReceiveJwtTokenAction = {
  type: typeof RECEIVE_JWT
  jwtToken: string
}

export type SetHasSentLoginTrackingEventAction = {
  type: typeof HAS_SENT_LOGIN_TRACKING_EVENT
}

export type SetDidRedirectToWorkspaceInvitesAction = {
  type: typeof DID_REDIRECT_TO_WORKSPACE_INVITES
}

export type AuthAction = ReceiveJwtTokenAction | SetHasSentLoginTrackingEventAction | SetDidRedirectToWorkspaceInvitesAction
