import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Skeleton,
  Tooltip,
  useDisclosure,
  useOutsideClick,
  useToken,
  VStack
} from '@chakra-ui/react'

import { useRef, useState, ReactNode } from 'react'

export interface Props {
  isActive: boolean
  isLoading: boolean
  isDisabled?: boolean
  isError?: boolean
  popoverContentWidth?: string
  triggerButtonContents: ReactNode
  children: ReactNode
}

export function FilterPopover({ isActive, isDisabled, isError, popoverContentWidth, triggerButtonContents, children, isLoading }: Props) {
  const { isOpen, onClose, onOpen } = useDisclosure()

  const ref = useRef<HTMLDivElement>(null)

  const [closedFromOutside, setClosedFromOutside] = useState(false)

  // This is done in response to an issue with closing the filter dropdown when clicking outside of it
  // see https://github.com/chakra-ui/chakra-ui/issues/7359#issuecomment-1698485043
  useOutsideClick({
    ref: ref,
    handler: () => {
      if (isOpen) {
        // Close the dropdown if it is open and the user clicks outside
        onClose()
        // we track that the dropdown was closed from outside so that we can prevent it from reopening
        // due to the trigger button click
        setClosedFromOutside(true)
      } else {
        setClosedFromOutside(false)
      }
    }
  })

  const [buttonHeight] = useToken('sizes', ['height.table.filters.button'])

  const button = (
    <Button
      onClick={() => {
        if (!isOpen && !closedFromOutside) {
          // Outside clicks are responsible for also closing the button so we don't want to reopen it
          onOpen()
        } else {
          onClose()
        }
        setClosedFromOutside(false)
      }}
      colorScheme={isActive ? 'blue' : 'gray'}
      variant={isActive ? 'filter-selected' : 'solid'}
      fontWeight={500}
      color={isActive ? undefined : 'activeColor'}
      rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
      isDisabled={isDisabled || isError}
      height={buttonHeight}
    >
      {triggerButtonContents}
    </Button>
  )

  return (
    <Box>
      <Popover placement="bottom-start" isOpen={isOpen}>
        <PopoverTrigger>
          {isError ? (
            <Tooltip label="We're having trouble loading this right now. You can try refreshing your page or trying again later if that doesn't work.">
              {button}
            </Tooltip>
          ) : (
            button
          )}
        </PopoverTrigger>
        <Portal>
          <PopoverContent ref={ref} width={popoverContentWidth}>
            <PopoverBody>
              <Skeleton isLoaded={!isLoading} width="100%">
                {isLoading ? <VStack height={140} /> : children}
              </Skeleton>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </Box>
  )
}
