import { useCallback } from 'react'

import { Button, Card, CardBody, CardHeader, Heading, Stack, useDisclosure, useToast } from '@chakra-ui/react'
import { useLocation } from 'wouter'
import { skipToken } from '@reduxjs/toolkit/query'

import TargetDeleteModal from './TargetDeleteModal'

import { getMessageFromRtkError } from '@/util/errors'

import { useDeleteTargetMutation } from '@/redux/api/targets'

import { useGetProjectMemberPermissionQuery } from '@/redux/api/projects'

import MayhemTableLoader from '@/components/MayhemTableLoader'
import { useWhoamiQuery } from '@/redux/api/workspace'

type Props = {
  workspaceSlug: string
  projectSlug: string
  targetSlug: string
  targetName: string
}

export function TargetSettingsGeneralPage({ workspaceSlug, projectSlug, targetSlug, targetName }: Props) {
  const toast = useToast()
  const [_location, setLocation] = useLocation()

  const { data: user } = useWhoamiQuery()
  const { slug: userSlug, is_admin: isMayhemAdmin } = user || {}
  const { isLoading: projectMemberPermissionIsLoading, data: projectPermission } = useGetProjectMemberPermissionQuery(
    userSlug
      ? {
          owner: workspaceSlug,
          projectSlug,
          userSlug
        }
      : skipToken,
    { skip: !userSlug }
  )

  const { isOpen: isDeleteConfirmationOpen, onOpen: onDeleteConfirmationOpen, onClose: onDeleteConfirmationClose } = useDisclosure()
  const [deleteTarget] = useDeleteTargetMutation()
  const onDeleteTarget = useCallback(async () => {
    try {
      toast({
        title: 'Target Deleted',
        description: `${targetSlug} was deleted`,
        status: 'success',
        duration: 5000,
        isClosable: true
      })
      setLocation(`/${workspaceSlug}`)
      await deleteTarget({ owner: workspaceSlug, projectSlug, targetSlug }).unwrap()
    } catch (err: unknown) {
      const message = getMessageFromRtkError(err)
      toast({
        title: 'Delete Target Failed',
        description: message,
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }, [toast, deleteTarget, workspaceSlug, projectSlug, targetSlug, setLocation])

  if (projectMemberPermissionIsLoading) {
    return <MayhemTableLoader />
  }

  const isAtLeastWritePermission = isMayhemAdmin || (projectPermission && projectPermission.permission !== 'READ')

  return (
    <>
      {isAtLeastWritePermission && (
        <Stack spacing={8}>
          <Card>
            <CardHeader>
              <Heading variant="cardHeading">Danger Zone</Heading>
            </CardHeader>
            <CardBody p={8}>
              <Button colorScheme="red" onClick={onDeleteConfirmationOpen}>
                Delete Target
              </Button>
            </CardBody>
          </Card>
          {isDeleteConfirmationOpen && workspaceSlug && projectSlug && targetSlug && (
            <TargetDeleteModal
              workspaceSlug={workspaceSlug}
              projectSlug={projectSlug}
              targetName={targetName}
              deleteHandler={onDeleteTarget}
              closeHandler={onDeleteConfirmationClose}
              open={isDeleteConfirmationOpen}
            />
          )}
        </Stack>
      )}
    </>
  )
}
