import { useEffect } from 'react'
import { Card, CardBody, CardHeader, Heading, Stack } from '@chakra-ui/react'
import { useLocation } from 'wouter'
import { skipToken } from '@reduxjs/toolkit/query'

import PermissionsList from './PermissionsList'

import { getProjectsSettingsPageTitle } from '@/titles'
import { ProjectVisibilityToggle } from '@/features/project-settings/ProjectVisibilityToggle'
import { useGetAccountQuery, useWhoamiQuery } from '@/redux/api/workspace'
import { useGetProjectMemberPermissionQuery, useGetProjectQuery } from '@/redux/api/projects'

import { Page } from '@/components/Page'
import { MayhemBreadcrumb } from '@/components/MayhemBreadcrumb'

type Props = {
  workspaceSlug: string
  projectSlug: string
}

export function AccessControlPage({ workspaceSlug, projectSlug }: Props) {
  const [location, setLocation] = useLocation()

  const { data: user } = useWhoamiQuery()
  const { slug: currentUserSlug, is_admin: isMayhemAdmin } = user || {}
  const { data: workspace, isLoading: isWorkspaceLoading } = useGetAccountQuery({ owner: workspaceSlug })
  const { data: projectPermission } = useGetProjectMemberPermissionQuery(
    currentUserSlug
      ? {
          owner: workspaceSlug,
          projectSlug,
          userSlug: currentUserSlug
        }
      : skipToken,
    { skip: !currentUserSlug }
  )
  const { data: project, isLoading: isProjectLoading } = useGetProjectQuery({
    owner: workspaceSlug,
    projectSlug
  })
  const { owner_name: workspaceName, project_name: projectName } = project || {}

  const canManageTeams = workspace?.is_org

  useEffect(() => {
    if (workspaceName && projectName) {
      document.title = getProjectsSettingsPageTitle(workspaceName, projectName, 'Access Control')
    }
  }, [location, workspaceName, projectName, setLocation])

  const isAtLeastWritePermission = isMayhemAdmin || (projectPermission && projectPermission.permission !== 'READ')

  return (
    <Page
      header={
        <MayhemBreadcrumb
          isLoading={isProjectLoading}
          items={[
            { text: 'Projects', path: `/${workspaceSlug}/-/projects` },
            { text: projectName!, path: `/${workspaceSlug}/${projectSlug}` },
            { text: 'Access Control' }
          ]}
        />
      }
    >
      <Heading mb={8}>Access Control</Heading>
      <Stack spacing={8}>
        {isAtLeastWritePermission && (
          <Card mb={8}>
            <CardHeader>
              <Heading variant="cardHeading">Project Visibility</Heading>
            </CardHeader>
            <CardBody p={8}>
              <ProjectVisibilityToggle workspaceSlug={workspaceSlug} projectSlug={projectSlug} isAdmin={isAtLeastWritePermission} />
            </CardBody>
          </Card>
        )}
        <PermissionsList
          workspaceSlug={workspaceSlug}
          projectSlug={projectSlug}
          canManageTeams={Boolean(canManageTeams)}
          isWorkspaceLoading={isWorkspaceLoading}
          isAtLeastWritePermission={Boolean(isAtLeastWritePermission)}
        />
      </Stack>
    </Page>
  )
}
