import Keycloak from 'keycloak-js'

// When proxying to a remote API (vs running against an API
// accessible on the same domain) some adjustments need to
// be made to how we configure keycloak. Specifically, iframes
// must be avoided in order to avoid cross-domain CSP issues
// (as the CSP rules come from the remote API).
const isLocalApi = import.meta.env.MODE !== 'remoteApi'

function getKeycloakInstance(): Keycloak {
  if (isLocalApi) {
    return new Keycloak('/api/v2/user-setting/keycloak.json')
  } else {
    return new Keycloak({
      clientId: 'mapi-ui-local',
      realm: 'mayhem',
      url: 'https://auth.mayhem.forallsecure.com/auth/'
    })
  }
}

const _kc: Keycloak = getKeycloakInstance()

let initKeycloakPromiseResolve: ((value: void | PromiseLike<void>) => void) | null = null

const initKeycloakPromise: Promise<void> = new Promise<void>((resolve) => {
  initKeycloakPromiseResolve = resolve
})

const initKeycloak = (): Promise<boolean> => {
  // Keycloak `init()` sets an undeclared property `didInitialize` when `init` is
  // first declared, which is why `_kc_` is being cast to `any`. Also, `init`
  // may NOT be called twice on the same `Keycloak` instance.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if ((_kc as any)['didInitialize']) {
    return Promise.resolve(true)
  }
  const silentCheckUrl = isLocalApi ? `${window.location.origin}/silent-check-sso.html` : undefined
  const actualInitPromise = _kc.init({
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: silentCheckUrl,
    pkceMethod: 'S256',
    messageReceiveTimeout: 20000,
    checkLoginIframe: false
  })

  actualInitPromise.then(() => initKeycloakPromiseResolve && initKeycloakPromiseResolve())
  return actualInitPromise
}

const doLoginToKeycloak = async (options?: Keycloak.KeycloakLoginOptions): Promise<void> => {
  // race condition between keycloak initialization and calling doLogin, so
  // serializing
  await initKeycloakPromise
  return _kc.login(options)
}
const redirectToRegistrationPage = async (options?: Keycloak.KeycloakRegisterOptions): Promise<void> => {
  await initKeycloakPromise
  return _kc.register(options)
}
const doLogout = _kc.logout
const isLoggedIn = (): boolean => !!_kc.token
const getToken = (): string | undefined => _kc.token
const updateToken = (): Promise<boolean | void> =>
  _kc.updateToken(5).catch(() => {
    doLoginToKeycloak()
  })
const getUsername = (): string | undefined => _kc.profile?.username
const getUserId = (): string | undefined => _kc.profile?.id
const hasRole = (roles_: string[]): boolean => roles_.some((role: string) => _kc.hasRealmRole(role))
const getRealm = (): string | undefined => _kc.realm

// Get a link to the account management URL. This will automatically
// populate the referer_uri so that callers are returned to the page
// from whence they came
const accountManagementUrl = _kc.createAccountUrl

const UserService = {
  initKeycloak,
  isLoggedInToKeycloak: isLoggedIn,
  doLogin: doLoginToKeycloak,
  doLogoutFromKeycloak: () => {
    // cancel all existing requests before logging out
    window.stop()
    doLogout()
  },
  redirectToRegistrationPage,
  getKeycloakBearerToken: getToken,
  updateKeycloakToken: updateToken,
  getUsername,
  hasRole,
  accountManagementUrl,
  getUserId,
  getRealm
}

export default UserService
