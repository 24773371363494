import { get } from '../../api'
import { AppThunk } from '../../redux/store'
import { clearMruWorkspace } from '../workspace-dashboard/mru.slice'

import {
  DID_REDIRECT_TO_WORKSPACE_INVITES,
  HAS_SENT_LOGIN_TRACKING_EVENT,
  RECEIVE_JWT,
  ReceiveJwtTokenAction,
  SetDidRedirectToWorkspaceInvitesAction,
  SetHasSentLoginTrackingEventAction
} from './reduxUtils'
import UserService from './UserService'

export function receiveJwtToken(jwtToken: string): ReceiveJwtTokenAction {
  return {
    type: RECEIVE_JWT,
    jwtToken
  }
}

export function setHasSentLoginTrackingEvent(): SetHasSentLoginTrackingEventAction {
  return { type: HAS_SENT_LOGIN_TRACKING_EVENT }
}

export function setDidRedirectToWorkspaceInvites(): SetDidRedirectToWorkspaceInvitesAction {
  return { type: DID_REDIRECT_TO_WORKSPACE_INVITES }
}

export function logout(): AppThunk<Promise<unknown>> {
  return async (dispatch) => {
    await dispatch(clearMruWorkspace())
    await UserService.doLogoutFromKeycloak()
  }
}

interface JwtTokenApiResponse {
  token: string
}

export function getJwtToken(): AppThunk<Promise<void>> {
  return async (dispatch) => {
    await UserService.initKeycloak()
    try {
      const resp = await get<JwtTokenApiResponse>('/api/v2/user/jwt?include_mayhem_claims=true')
      dispatch(receiveJwtToken(resp.token))
    } catch (err) {
      // The api is not yet configured so the request fails.
      // We supress the error here, to prevent a crash
    }
  }
}
