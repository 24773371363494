import { ReactNode, useEffect, useState } from 'react'
import { useLocation, useRoute } from 'wouter'

import { Button, HStack, Text, useToast } from '@chakra-ui/react'

import { useGetCommitHashQuery } from '@/redux/api/version'
import { usePostLocationMutation, useWhoamiQuery } from '@/redux/api/workspace'

const CHECK_UPDATE_INTERVAL = 1000 * 60 * 5 // 5 minutes

export function LocationTracker({ children }: { children: ReactNode }) {
  const [location] = useLocation()
  // Used to get information about the browser address

  // Use to log whenever an authenticated user accesses a location in order
  // to track UI usage.
  //
  // Caveats:
  // ========
  //   * Modal navigation will NOT be covered UNLESS it includes a location
  //     changes
  //   * RAW paths will be recorded... not configured route templates
  //     (ie: '/bob/projects' instead of '/:owner/projects')
  //   * Only AUTHENTICATED users will be tracked
  //
  const [postLocation] = usePostLocationMutation()
  const [commitHash, setCommitHash] = useState<string | undefined>()

  const { data: versionData } = useGetCommitHashQuery(undefined, { pollingInterval: CHECK_UPDATE_INTERVAL })
  const { data: user } = useWhoamiQuery()
  const isAuthenticated = !!user

  const toast = useToast()

  useEffect(() => {
    if (versionData && versionData.mChartCommitHash) {
      setCommitHash((prevCommitHash) => {
        if (prevCommitHash && prevCommitHash !== versionData.mChartCommitHash) {
          toast({
            position: 'bottom-right',
            title: 'New Version of Mayhem Available',
            description: (
              <HStack justifyContent="space-between">
                <Text>A new version of Mayhem is now available! Please refresh to see the latest version.</Text>
                <Button
                  variant="outline"
                  onClick={() => {
                    // refresh the page
                    window.location.reload()
                  }}
                >
                  Refresh
                </Button>
              </HStack>
            ),
            isClosable: true,
            variant: 'subtle',
            duration: 9000
          })
          console.warn("You're on an old version of Mayhem. Please refresh your browser to get the latest version.")
        }
        return versionData.mChartCommitHash
      })
    }
  }, [versionData, commitHash, setCommitHash, toast])

  const [_isMatch, workspaceParams] = useRoute('/:workspaceSlug/*?')
  const workspaceSlug = workspaceParams?.workspaceSlug

  useEffect(() => {
    // Tracking telemetry. Let the API know that the user has navigated to a
    // different route in order to measure where people get stuck in their
    // onboarding journey.
    try {
      // Limit to authenticated users
      if (isAuthenticated) {
        const url = `${window.location.origin}${location}`
        const title = `${document.title}`
        const reportedWorkspace = workspaceSlug === undefined || workspaceSlug === '-' ? 'None' : workspaceSlug

        postLocation({ location: { url: url, title: title, workspace: reportedWorkspace } })
      }
    } catch (err) {
      // Non breaking - but log to console to help debug locally
      console.warn(`Failed to post location to API - ${err}`)
    }
  }, [location, workspaceSlug, postLocation, isAuthenticated])

  return <>{children}</>
}
