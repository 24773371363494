import { Badge, IconButton, Text, Tooltip } from '@chakra-ui/react'
import CloseIcon from '@material-design-icons/svg/sharp/close.svg?react'

interface Props {
  filterString: string
  hasCloseButton?: boolean
  onCloseButtonClick?: () => void
}

export function ResponseFilterBadge({ filterString, hasCloseButton, onCloseButtonClick }: Props) {
  return (
    <Badge variant="info" key={filterString} display="flex" alignItems="center" gap={2} maxWidth="100%">
      <Tooltip hasArrow bg="gray.100" color="black" label={filterString} aria-label="Full text">
        <Text overflow="hidden" textOverflow="ellipsis" fontWeight={500} textTransform="none" maxWidth={hasCloseButton ? '95%' : '100%'}>
          {filterString}
        </Text>
      </Tooltip>
      {hasCloseButton && (
        <IconButton
          variant="ghost"
          size="xs"
          padding={0}
          minWidth={0}
          aria-label="remove filter string"
          icon={<CloseIcon width={12} />}
          onClick={onCloseButtonClick}
        />
      )}
    </Badge>
  )
}
