import { ReactNode, useEffect } from 'react'

import { getJwtToken } from '../auth/actions'

import { useAppDispatch, useAppSelector } from '@/hooks'

export function WithDsbomToken({ children }: { children: ReactNode }) {
  const token = useAppSelector((state) => state.auth.mdsbomJwtToken)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!token) {
      dispatch(getJwtToken())
    }
  }, [token, dispatch])

  return <>{children}</>
}
