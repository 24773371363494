import { Card, CardBody, CardHeader, Heading } from '@chakra-ui/react'

import { useEffect } from 'react'

import { Page } from '@/components/Page'
import { useCanCreateWorkspace } from '@/hooks'

import { CreateOrganizationForm } from '@/features/organization/CreateOrganizationForm'

import { getCreateWorkspacePageTitle } from '@/titles'

export const CreateWorkspacePage = () => {
  const canCreateWorkspace = useCanCreateWorkspace()

  useEffect(() => {
    document.title = getCreateWorkspacePageTitle()
  }, [])

  return (
    <Page>
      <Card>
        <CardHeader>
          <Heading variant="cardHeading">{canCreateWorkspace ? 'Create a new Workspace' : 'The current user cannot create workspaces.'}</Heading>
        </CardHeader>
        <CardBody p={8}>{canCreateWorkspace && <CreateOrganizationForm />}</CardBody>
      </Card>
    </Page>
  )
}
