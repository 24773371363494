import { useCallback } from 'react'
import {
  Button,
  HStack,
  Icon,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SkeletonText,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react'

import { getMessageFromRtkError } from '../../util/errors'
import JiraIcon from '../../images/integrations/jira_logo.svg?react'
import { useVersionQuery } from '../../redux/api/version'
import { useWhoamiQuery } from '../../redux/api/workspace'

import { CreateJiraIssueForm } from './CreateJiraIssueForm'
import { redirectToJiraOauth } from './oauth'

type Props = {
  defectNumber: number
  workspaceSlug: string
  projectSlug: string
  targetSlug: string
}

export function CreateJiraIssueButton({ workspaceSlug, projectSlug, defectNumber, targetSlug }: Props) {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const toast = useToast()
  const { data: currentUser } = useWhoamiQuery()
  const { data: apiData } = useVersionQuery()

  const clientId = apiData?.atlassian_client_id ?? undefined
  const hasAtlassianAuth = currentUser?.has_atlassian_auth

  const createIssue = useCallback(async () => {
    try {
      if (hasAtlassianAuth) {
        onOpen()
      } else {
        redirectToJiraOauth(clientId)
      }
    } catch (err: unknown) {
      toast({
        title: 'Atlassian OAuth Failed',
        description: getMessageFromRtkError(err),
        status: 'error',
        duration: 9000,
        isClosable: true
      })
    }
  }, [hasAtlassianAuth, toast, clientId, onOpen])

  if (!currentUser) {
    return <SkeletonText noOfLines={1} />
  }

  return (
    <>
      <Button leftIcon={<Icon as={JiraIcon} />} onClick={createIssue} variant="navigation">
        Jira Issue
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <HStack>
              <Icon as={JiraIcon} />
              <Text>Create Jira Issue</Text>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <CreateJiraIssueForm workspaceSlug={workspaceSlug} projectSlug={projectSlug} defectNumber={defectNumber} targetSlug={targetSlug} />
        </ModalContent>
      </Modal>
    </>
  )
}
