import { SimpleGrid, GridItem } from '@chakra-ui/react'

import { useEffect } from 'react'

import { getAdminSettingsPageTitle } from '../../titles'

import { AtlassianIntegrationSettingsCard } from './AtlassianIntegrationsSettingsCard'

export function IntegrationsAdminSettings() {
  useEffect(() => {
    document.title = getAdminSettingsPageTitle('Integrations')
  }, [])

  return (
    <SimpleGrid columns={2} spacing={8}>
      <GridItem rowSpan={2} colSpan={{ base: 2, lg: 1 }}>
        <AtlassianIntegrationSettingsCard />
      </GridItem>
    </SimpleGrid>
  )
}
