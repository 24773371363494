import { useEffect } from 'react'
import { Button, Card, CardHeader, Heading, useDisclosure } from '@chakra-ui/react'
import { skipToken } from '@reduxjs/toolkit/query'

import { CreateWebhookModal } from '@/features/admin-webhooks/CreateWebhookModal'
import { WebhookList } from '@/features/admin-webhooks/WebhookList'
import { getProjectsSettingsPageTitle } from '@/titles'
import { useAppDispatch } from '@/hooks'
import { setWebhook } from '@/features/admin-webhooks/webhooks.slice'
import { useGetProjectMemberPermissionQuery, useGetProjectQuery } from '@/redux/api/projects'

import { Page } from '@/components/Page'
import { MayhemBreadcrumb } from '@/components/MayhemBreadcrumb'
import { useWhoamiQuery } from '@/redux/api/workspace'

type Props = {
  workspaceSlug: string
  projectSlug: string
}

export function ProjectSettingsWebhooksPage({ workspaceSlug, projectSlug }: Props) {
  const dispatch = useAppDispatch()
  const { isOpen, onClose, onOpen } = useDisclosure()

  const { data: user, isLoading: isUserLoading } = useWhoamiQuery()
  const { slug: userSlug, is_admin: isMayhemAdmin } = user || {}
  const { data: projectPermission } = useGetProjectMemberPermissionQuery(
    userSlug
      ? {
          owner: workspaceSlug,
          projectSlug,
          userSlug
        }
      : skipToken,
    { skip: !userSlug }
  )
  const { data: project, isLoading: isProjectLoading } = useGetProjectQuery({ owner: workspaceSlug, projectSlug })
  const { owner_name: workspaceName, project_name: projectName } = project || {}

  useEffect(() => {
    if (workspaceName && projectName) {
      document.title = getProjectsSettingsPageTitle(workspaceName, projectName, 'Webhooks')
    }
  }, [workspaceName, projectName])

  useEffect(() => {
    dispatch(setWebhook({ subscription: { owner: workspaceSlug } }))
  }, [dispatch, workspaceSlug])

  const isAdminPermission = isMayhemAdmin || (projectPermission && projectPermission.permission === 'ADMIN')

  return (
    <Page
      header={
        <MayhemBreadcrumb
          isLoading={isProjectLoading || isUserLoading}
          items={[
            { text: 'Projects', path: `/${workspaceSlug}/-/projects` },
            { text: projectName!, path: `/${workspaceSlug}/${projectSlug}` },
            { text: 'Webhooks' }
          ]}
        />
      }
    >
      <Heading mb={8}>Webhooks</Heading>
      <Card>
        <CardHeader>
          <Heading variant="cardHeading">Webhooks</Heading>
          {isAdminPermission && <Button onClick={onOpen}>Create New Webhook</Button>}
        </CardHeader>
        <WebhookList workspaceSlug={workspaceSlug} projectSlug={projectSlug} />
      </Card>
      <CreateWebhookModal isOpen={isOpen} onClose={onClose} subscription={{ project_slug: projectSlug, owner: workspaceSlug }} />
    </Page>
  )
}
