/*
 * Copyright (C) 2016 ForAllSecure, Inc. - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import {
  SetMayhemBotSettingsActionType,
  SET_ENROLLMENT_CONFIG,
  SET_MAYHEM_BOT_SETTINGS,
  SET_SYSTEM_RESOURCE_QUOTA,
  SystemResourceQuotaActionType,
  UsersActionsType
} from '../admin/types'

import { ConfigAction, SET_API_INFO, SET_MAYHEM_INFO } from './actions'

type Config = {
  apiVersion?: string
  emailSendingAvailable: boolean | undefined
  environment?: string
  isOfflineMode: boolean | undefined
  isPublicDeployment: boolean | undefined
  keycloakInitialized: boolean | undefined
  maxActiveRuns: number | undefined
  maxRunDuration: number | undefined
  mayhemVersion: string | undefined
  mbotUtilization?: unknown
  mbotTaskDuration?: number
  mbotReserve?: number
  mbotOwner?: string
  mbotSlug?: string
}

export default function configReducer(
  state: Config = {
    emailSendingAvailable: undefined,
    isOfflineMode: true,
    isPublicDeployment: undefined,
    maxActiveRuns: undefined,
    maxRunDuration: undefined,
    keycloakInitialized: undefined,
    mayhemVersion: undefined
  },
  action: ConfigAction | UsersActionsType | SystemResourceQuotaActionType | SetMayhemBotSettingsActionType
): Config {
  switch (action.type) {
    case SET_API_INFO:
      return {
        ...state,
        apiVersion: action.api.version,
        environment: action.api.environment,
        isOfflineMode: action.api.offline,
        isPublicDeployment: action.api.public
      }

    case SET_MAYHEM_INFO:
      return {
        ...state,
        mayhemVersion: action.mayhem.mayhemVersion
      }

    case SET_ENROLLMENT_CONFIG:
      return {
        ...state,
        emailSendingAvailable: action.enrollmentSettings.emailSendingAvailable,
        maxActiveRuns: action.enrollmentSettings.maxActiveRuns,
        maxRunDuration: action.enrollmentSettings.maxRunDuration,
        mbotUtilization: action.enrollmentSettings.mbotUtilization,
        mbotTaskDuration: action.enrollmentSettings.mbotTaskDuration,
        mbotReserve: action.enrollmentSettings.mbotReserve,
        mbotOwner: action.enrollmentSettings.mbotOwner,
        mbotSlug: action.enrollmentSettings.mbotSlug
      }

    case SET_SYSTEM_RESOURCE_QUOTA:
      return {
        ...state,
        maxActiveRuns: action.quota.maxActiveRuns,
        maxRunDuration: action.quota.maxRunDuration
      }

    case SET_MAYHEM_BOT_SETTINGS:
      return {
        ...state,
        mbotUtilization: action.payload.mbotUtilization
      }

    default:
      return state
  }
}
