import { Stack } from '@chakra-ui/react'

import { useLocation } from 'wouter'

import { DataTab, DataTabs } from '../../components/DataTabs'
import { Page } from '../../components/Page'

import { DsbomDefectsList } from '../dsbom-defects/DsbomDefectsList'
import { DsbomImagesList } from '../dsbom-images/DsbomImagesList'
import { DsbomReportsList } from '../dsbom-reports/DsbomReportsList'

import { useGetMdsbomWorkspaceImageReposQuery } from '../../redux/api/mdsbom'

import { DsbomWorkspaceBigStats } from './DsbomWorkspaceBigStats'

interface Props {
  workspaceSlug: string
}

export function DsbomWorkspacePage({ workspaceSlug }: Props) {
  const [, setLocation] = useLocation()

  // Redirect the user to the getting started page if there are no image repos
  // associated with the workspace
  const { data, isLoading } = useGetMdsbomWorkspaceImageReposQuery({ workspace: workspaceSlug, perPage: 1 })
  if (data && data.count === 0) {
    setLocation(`/${workspaceSlug}/-/dynamic-sbom/start`)
  }

  const tabData: DataTab[] = [
    {
      label: 'Defects',
      content: <DsbomDefectsList workspaceSlug={workspaceSlug} />,
      route: `/${workspaceSlug}/-/dynamic-sbom`
    },
    {
      label: 'Reports',
      content: <DsbomReportsList workspaceSlug={workspaceSlug} />,
      route: `/${workspaceSlug}/-/dynamic-sbom/-/reports`
    },
    {
      label: 'Images',
      content: <DsbomImagesList workspaceSlug={workspaceSlug} />,
      route: `/${workspaceSlug}/-/dynamic-sbom/-/images`
    }
  ]

  return (
    <Page isLoading={isLoading}>
      <Stack spacing={4}>
        <DsbomWorkspaceBigStats workspaceSlug={workspaceSlug} />
        <DataTabs isLazy variant="line" width="full" tabs={tabData} />
      </Stack>
    </Page>
  )
}
