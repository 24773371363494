import { Route, RouteProps, DefaultParams, Path } from 'wouter'
import { skipToken } from '@reduxjs/toolkit/query'

import { MayhemPageLoader } from '../../components/MayhemPageLoader'

import { LocationTracker } from '../user/LocationTracker'
import { LoginTracker } from '../user/LoginTracker'
import { WorkspaceInvitesRedirect } from '../invites/WorkspaceInvitesRedirect'

import UserService from './UserService'
import { KeycloakInitialization } from './KeycloakInitialization'

import { useWhoamiQuery } from '@/redux/api/workspace'

export function PrivateMatch<T extends DefaultParams | undefined = undefined, RoutePath extends Path = Path>(
  props: RouteProps<T, RoutePath>
): JSX.Element {
  return (
    <KeycloakInitialization>
      <PrivateMatchInner {...props} />
    </KeycloakInitialization>
  )
}

function PrivateMatchInner<T extends DefaultParams | undefined = undefined, RoutePath extends Path = Path>(
  props: RouteProps<T, RoutePath>
): JSX.Element {
  const isLoggedInToKeycloak = UserService.isLoggedInToKeycloak()
  const { isLoading, isError } = useWhoamiQuery(isLoggedInToKeycloak ? undefined : skipToken, { skip: !isLoggedInToKeycloak })

  if (!isLoggedInToKeycloak || isError) {
    UserService.doLogin()
  }

  if (isLoading) {
    return <MayhemPageLoader />
  }

  return (
    <LocationTracker>
      <LoginTracker>
        <WorkspaceInvitesRedirect>
          <Route {...props} />
        </WorkspaceInvitesRedirect>
      </LoginTracker>
    </LocationTracker>
  )
}
