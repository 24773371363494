import { useEffect, useState } from 'react'

import UserService from './UserService'

import { MayhemPageLoader } from '@/components/MayhemPageLoader'

interface Props {
  children: React.ReactNode
}

export function KeycloakInitialization({ children }: Props) {
  // NOTE: Avoid race conditions for queries that use RTK Query before
  // Keycloak has completed initialization (enabled or not). If ANY
  // RTK Queries happen before Keycloak has completed initialization,
  // it is possible to make unauthenticated requests that would normally
  // have been authenticated, resulting in a 401 response; and an infinite
  // loop of loading and redirecting to login!
  const [isKeycloakInitialized, setIsKeycloakInitialized] = useState(false)
  useEffect(() => {
    UserService.initKeycloak().then(() => setIsKeycloakInitialized(true)) // TODO Catch errors
  }, [])

  if (!isKeycloakInitialized) {
    return <MayhemPageLoader />
  }

  return <>{children}</>
}
