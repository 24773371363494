import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Heading, Show, Stack } from '@chakra-ui/react'
import { Link as ChakraLink } from '@chakra-ui/react'

import { getWorkspacePageTitle } from '../../titles'
import { Page } from '../../components/Page'

import { setMruWorkspace } from '../workspace-dashboard/mru.slice'

import { WorkspaceNewProjectActions } from './WorkspaceNewProjectActions'

import { WorkspaceNewProjectResources } from './WorkspaceNewProjectResources'

import { WorkspaceNewProjectCards } from './WorkspaceNewProjectCards'

import { useWhoamiQuery } from '@/redux/api/workspace'

type Props = { workspaceSlug: string }

export function WorkspaceNewProjectPage({ workspaceSlug }: Props) {
  const { data: user } = useWhoamiQuery()
  const firstName = user?.first_name
  const dispatch = useDispatch()
  useEffect(() => {
    document.title = getWorkspacePageTitle(workspaceSlug, 'Getting Started')
    dispatch(setMruWorkspace(workspaceSlug))
  }, [workspaceSlug, dispatch])

  return (
    <Page
      header={
        <Show above="lg">
          <Heading size="lg">Welcome{firstName ? `, ${firstName}` : ''}!</Heading>
        </Show>
      }
      paddingX={8}
    >
      <Stack spacing={8}>
        <WorkspaceNewProjectCards workspaceSlug={workspaceSlug} />

        <Box display="flex" flexDirection={{ base: 'column', xl: 'row' }} gap={8}>
          {/* Reverse the cards order on mobile */}
          <WorkspaceNewProjectActions order={{ base: 2, xl: 1 }} workspaceSlug={workspaceSlug} />
          <WorkspaceNewProjectResources order={{ base: 1, xl: 2 }} />
        </Box>
        <ChakraLink variant="brand" href="https://docs.mayhem.security/support/contact/" isExternal>
          Need additional support? Contact us!
        </ChakraLink>
      </Stack>
    </Page>
  )
}
