import { useState, ReactNode } from 'react'
import { Button, HStack, Input, Select, SkeletonText, Text, Tooltip, useToast, VStack } from '@chakra-ui/react'

import { ResponseFilterBadge } from './ResponseFilterBadge'

import { FilterPopover } from '@/components/FilterPopover'

interface Props {
  excludeMatching?: boolean
  setExcludeMatching: (excludeMatching: boolean) => void
  searchStrings?: string[]
  setSearchStrings: (searchStrings: string[]) => void
  responseFilterError?: ReactNode
  isLoading: boolean
}

export function ResponseFilter({ excludeMatching, setExcludeMatching, searchStrings, setSearchStrings, responseFilterError, isLoading }: Props) {
  const toast = useToast()
  const [currentText, setCurrentText] = useState('')

  function handleApply() {
    if (searchStrings?.includes(currentText)) {
      toast({
        title: `Cannot add duplicate response filter: "${currentText}"`,
        description: `The response filter "${currentText}" is already applied.`,
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    } else {
      setSearchStrings([...(searchStrings || []), currentText])
      setCurrentText('')
    }
  }

  const renderTriggerText = () => {
    if (isLoading) {
      return <SkeletonText noOfLines={1} width={44} />
    }
    if (searchStrings && searchStrings.length > 0) {
      return (
        <>
          <Text as="span" textColor={excludeMatching ? 'red.500' : undefined}>
            {excludeMatching ? 'Does Not Contain' : 'Contains'}
          </Text>
          &nbsp;“{searchStrings[0].length > 5 ? searchStrings[0].slice(0, 5) + '...' : searchStrings[0]}”
          {searchStrings.length > 1 && (
            <>
              &nbsp;
              <Tooltip hasArrow bg="gray.100" color="black" label={searchStrings.slice(1).join(', ')} aria-label="selected items tooltip">
                <Text color="brand.500"> +{searchStrings.length - 1}</Text>
              </Tooltip>
            </>
          )}
        </>
      )
    }
    return 'Response'
  }

  return (
    <FilterPopover
      isLoading={isLoading}
      isActive={!!searchStrings && searchStrings.length > 0}
      triggerButtonContents={renderTriggerText()}
      isError={false}
      popoverContentWidth="xl"
    >
      <VStack spacing={4} alignItems="flex-start">
        <Select
          width="auto"
          variant="filled"
          height={9}
          value={excludeMatching ? 'does-not-contain' : 'contains'}
          onChange={(e) => setExcludeMatching(e.target.value === 'does-not-contain')}
        >
          <option value="contains">Contains</option>
          <option value="does-not-contain">Does Not Contain</option>
        </Select>
        <HStack spacing={4} width="100%">
          <Input
            placeholder={`Input a String of the response you ${excludeMatching ? "don't want" : 'want'} to see...`}
            flexGrow={1}
            width="initial"
            value={currentText}
            onChange={(e) => setCurrentText(e.target.value)}
          />
          <Button disabled={!currentText} onClick={handleApply}>
            Apply
          </Button>
        </HStack>
        {responseFilterError}
        <HStack spacing={4} width="100%" display="flex" flexWrap="wrap">
          {searchStrings?.map((filterString) => (
            <ResponseFilterBadge
              key={filterString}
              filterString={filterString}
              hasCloseButton={true}
              onCloseButtonClick={() => setSearchStrings(searchStrings?.filter((str) => str !== filterString))}
            />
          ))}
        </HStack>
      </VStack>
    </FilterPopover>
  )
}
