import { mayhemApi as api } from './mayhemApi'
export const addTagTypes = ['defect', 'internal', 'run', 'testcase report'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getDefectsByRun: build.query<GetDefectsByRunApiResponse, GetDefectsByRunApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/run/${queryArg.runNumber}/defect`,
          params: {
            api_endpoint_paths: queryArg.apiEndpointPaths,
            crashes_only: queryArg.crashesOnly,
            cwe_number: queryArg.cweNumber,
            endpoint_method_paths: queryArg.endpointMethodPaths,
            last_seen_gte: queryArg.lastSeenGte,
            last_seen_lt: queryArg.lastSeenLt,
            offset: queryArg.offset,
            per_page: queryArg.perPage,
            phase: queryArg.phase,
            q: queryArg.q,
            response_filter: queryArg.responseFilter,
            severity: queryArg.severity,
            sorted_by: queryArg.sortedBy
          }
        }),
        providesTags: ['defect']
      }),
      getExeReports: build.query<GetExeReportsApiResponse, GetExeReportsApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/run/${queryArg.runNumber}/exe_report`
        }),
        providesTags: ['internal']
      }),
      getRunsByOwner: build.query<GetRunsByOwnerApiResponse, GetRunsByOwnerApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/run`,
          params: {
            end_date: queryArg.endDate,
            offset: queryArg.offset,
            per_page: queryArg.perPage,
            project_slug: queryArg.projectSlug,
            run_type: queryArg.runType,
            search: queryArg.search,
            sorted_by: queryArg.sortedBy,
            start_date: queryArg.startDate,
            status: queryArg.status,
            target_slug: queryArg.targetSlug
          }
        }),
        providesTags: ['run']
      }),
      postTargetRun: build.mutation<PostTargetRunApiResponse, PostTargetRunApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/run`,
          method: 'POST',
          body: queryArg.runPost,
          headers: { 'X-Mayhem-Client-Version': queryArg['X-Mayhem-Client-Version'] }
        }),
        invalidatesTags: ['run']
      }),
      getRun: build.query<GetRunApiResponse, GetRunApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/run/${queryArg.runNumber}`
        }),
        providesTags: ['run']
      }),
      deleteRun: build.mutation<DeleteRunApiResponse, DeleteRunApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/run/${queryArg.runNumber}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['run']
      }),
      getRunEndpoints: build.query<GetRunEndpointsApiResponse, GetRunEndpointsApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/run/${queryArg.runNumber}/endpoints`,
          params: {
            ordered_by: queryArg.orderedBy,
            q: queryArg.q,
            request_type: queryArg.requestType,
            sorted_by: queryArg.sortedBy,
            status: queryArg.status,
            tags: queryArg.tags
          }
        }),
        providesTags: ['run']
      }),
      putRunPhase: build.mutation<PutRunPhaseApiResponse, PutRunPhaseApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/run/${queryArg.runNumber}/phase/${queryArg.phaseName}`,
          method: 'PUT',
          body: queryArg.runPhase
        }),
        invalidatesTags: ['internal']
      }),
      getTestcasesOverTime: build.query<GetTestcasesOverTimeApiResponse, GetTestcasesOverTimeApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/run/${queryArg.runNumber}/testcase_over_time`
        }),
        providesTags: ['internal']
      }),
      getDefectsOverTime: build.query<GetDefectsOverTimeApiResponse, GetDefectsOverTimeApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/run/${queryArg.runNumber}/defect_over_time`
        }),
        providesTags: ['internal']
      }),
      getSourceMayhemfile: build.query<GetSourceMayhemfileApiResponse, GetSourceMayhemfileApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/run/${queryArg.runNumber}/Mayhemfile.source`
        }),
        providesTags: ['internal']
      }),
      getMaterializedMayhemfile: build.query<GetMaterializedMayhemfileApiResponse, GetMaterializedMayhemfileApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/run/${queryArg.runNumber}/Mayhemfile`,
          params: { internal: queryArg.internal }
        }),
        providesTags: ['internal']
      }),
      getAllRuns: build.query<GetAllRunsApiResponse, GetAllRunsApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/run`,
          params: {
            end_date: queryArg.endDate,
            offset: queryArg.offset,
            per_page: queryArg.perPage,
            project_slug: queryArg.projectSlug,
            run_type: queryArg.runType,
            search: queryArg.search,
            sorted_by: queryArg.sortedBy,
            start_date: queryArg.startDate,
            status: queryArg.status,
            target_slug: queryArg.targetSlug
          }
        }),
        providesTags: ['run']
      }),
      getRunStatsByUser: build.query<GetRunStatsByUserApiResponse, GetRunStatsByUserApiArg>({
        query: (queryArg) => ({ url: `/api/v2/user/${queryArg.userSlug}/run-stat`, params: { status: queryArg.status } }),
        providesTags: ['run']
      }),
      getRunNextSteps: build.query<GetRunNextStepsApiResponse, GetRunNextStepsApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/run/${queryArg.runNumber}/nextstep`
        }),
        providesTags: ['run']
      }),
      getTestcaseResultsByRun: build.query<GetTestcaseResultsByRunApiResponse, GetTestcaseResultsByRunApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/run/${queryArg.runNumber}/testcase-result`,
          params: {
            crashes_only: queryArg.crashesOnly,
            offset: queryArg.offset,
            per_page: queryArg.perPage,
            phase: queryArg.phase,
            q: queryArg.q,
            sorted_by: queryArg.sortedBy,
            status_filters: queryArg.statusFilters,
            type_filters: queryArg.typeFilters
          }
        }),
        providesTags: ['testcase report']
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as api }
export type GetDefectsByRunApiResponse = /** status 200 Returns list of defects within a single run.

    :param run: defects from this run will be fetched
    :param phase: optionally limit defects to those from a specific phase
    :param crashes_only: optionally limit defects to only those related to a crash
    :param severity: optionally limit defects to a specific severity level
    :param api_endpoint_path: optionally filter by defect api endpoint path
    :param cwe_number: optionally filter by cwe number
    :param sorted_by: optionally sort by a specific defect property
    :param ordered_by: optional sort order (desc | asc)
    :param path: optionally filter by defect path
    :param offset: the offset of the results to retrieve, 0-indexed
    :param per_page: how many results to retrieve per page

    Returns:
        list(Defect) -- list of defect objects in the specified run.
        count(int) -- total number of defects.
        list(Defect type) -- list of defect types in the specified run.
     */ RunDefectList
export type GetDefectsByRunApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The number of the run for the specified project / target. */
  runNumber: number
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
  apiEndpointPaths?: (string | null)[]
  crashesOnly?: boolean
  cweNumber?: number | null
  /** A JSON-encoded list of objects describing endpoints like
                [{"path":"/my/path", "method": "GET"}, {"path":"/another/path", "method":
                "POST"}] which filters the defect results to defects which match *ANY* path
                AND method pair of an endpoint. */
  endpointMethodPaths?: string
  lastSeenGte?: string
  lastSeenLt?: string
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
  phase?: string | null
  /** case-insensitive search across defect title, CWE #, and endpoint path */
  q?: string
  /** A JSON-encoded object describing strings to search in
                response data and whether to include or exlcude matching entries like
                {"qs": ["hello", "error"], "exclude_matching": false} which filters the
                defect results to defects which match *ANY* or *NONE* (based on
                "exclude_matching") words in response data */
  responseFilter?: string
  severity?: ('low' | 'medium' | 'high')[]
  sortedBy?: (
    | 'created_at'
    | '-created_at'
    | 'severity'
    | '-severity'
    | 'defect_state'
    | '-defect_state'
    | 'defect_title'
    | '-defect_title'
    | 'endpoint'
    | '-endpoint'
    | 'last_seen'
    | '-last_seen'
  )[]
}
export type GetExeReportsApiResponse = /** status 200
    Fetches all the static analysis reports with distinct sha256's for a given run
     */ ExeReports
export type GetExeReportsApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The number of the run for the specified project / target. */
  runNumber: number
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
}
export type GetRunsByOwnerApiResponse = /** status 200 Returns list of runs for the given owner in
    reverse chronological order.

    Returns:
        list(Run) -- list of run objects from the target.
     */ RunsPage
export type GetRunsByOwnerApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  endDate?: string
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
  projectSlug?: string[]
  runType?: 'api' | 'code'
  search?: string
  sortedBy?: (
    | 'coverage'
    | '-coverage'
    | 'defects'
    | '-defects'
    | 'owner'
    | '-owner'
    | 'defect_count_high'
    | '-defect_count_high'
    | 'defect_count_medium'
    | '-defect_count_medium'
    | 'defect_count_low'
    | '-defect_count_low'
    | 'project_name'
    | '-project_name'
    | 'target_name'
    | '-target_name'
    | 'run_number'
    | '-run_number'
    | 'testcase_pass_percent'
    | '-testcase_pass_percent'
    | 'created_at'
    | '-created_at'
  )[]
  startDate?: string
  status?: ('pending' | 'running' | 'completed' | 'failed' | 'stopped')[]
  targetSlug?: string[]
}
export type PostTargetRunApiResponse = /** status 200 Creates a new run for the given target.

    Returns:
        Run -- The run object that was created.
     */ RunFull
export type PostTargetRunApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
  /** API Client version. */
  'X-Mayhem-Client-Version'?: string
  runPost: RunPost
}
export type GetRunApiResponse = /** status 200 Retrieves a single Run that matches the given
    owner/project/target/run_number specifiers.

    Returns:
        Run -- The matching run.
     */ RunFull
export type GetRunApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The number of the run for the specified project / target. */
  runNumber: number
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
}
export type DeleteRunApiResponse = /** status 202 Deletes a single target from a owner.

    Returns:
        {"result": "ok"} -- On successful deletion.
     */ Ok
export type DeleteRunApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The number of the run for the specified project / target. */
  runNumber: number
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
}
export type GetRunEndpointsApiResponse = /** status 200
    Retrieves the endpoints for the target of an APIrun. By default, the endpoints
    are sorted by their status. The caller can also provide filters for the tags,
    status, request type, and a search query for the endpoint path.

    Returns:
        Endpoints -- the list of endpoints for that target.
        Tags -- the list of open api tags for that target.
     */ RunEndpoints
export type GetRunEndpointsApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The number of the run for the specified project / target. */
  runNumber: number
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
  orderedBy?: 'asc' | 'desc'
  /** case insensitive search across path */
  q?: string | null
  requestType?: ('GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'FAKEMETHOD')[]
  sortedBy?:
    | (
        | 'STATUS'
        | 'PATH'
        | 'COVERAGE'
        | 'LATENCY_P50'
        | 'LATENCY_P90'
        | 'LATENCY_P99'
        | 'DEFECT_COUNT_HIGH'
        | 'DEFECT_COUNT_MEDIUM'
        | 'DEFECT_COUNT_LOW'
        | null
      )
    | null
  status?: ('high' | 'medium' | 'low' | 'passing')[]
  tags?: string[]
}
export type PutRunPhaseApiResponse = /** status 200 Updates the state of a phase */ RunPhase
export type PutRunPhaseApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  phaseName: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The number of the run for the specified project / target. */
  runNumber: number
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
  runPhase: RunPhase
}
export type GetTestcasesOverTimeApiResponse = /** status 200 Returns number of testcases over time. */ RunStat
export type GetTestcasesOverTimeApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The number of the run for the specified project / target. */
  runNumber: number
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
}
export type GetDefectsOverTimeApiResponse = /** status 200 Returns number of testcases over time. */ RunStat
export type GetDefectsOverTimeApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The number of the run for the specified project / target. */
  runNumber: number
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
}
export type GetSourceMayhemfileApiResponse = /** status 200 Returns the Mayhemfile for the run */ string
export type GetSourceMayhemfileApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The number of the run for the specified project / target. */
  runNumber: number
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
}
export type GetMaterializedMayhemfileApiResponse = /** status 200 Returns the materialized Mayhemfile for the run */ Blob
export type GetMaterializedMayhemfileApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The number of the run for the specified project / target. */
  runNumber: number
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
  internal?: boolean
}
export type GetAllRunsApiResponse = /** status 200 Returns all runs */ RunsPage
export type GetAllRunsApiArg = {
  endDate?: string
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
  projectSlug?: string[]
  runType?: 'api' | 'code'
  search?: string
  sortedBy?: (
    | 'coverage'
    | '-coverage'
    | 'defects'
    | '-defects'
    | 'owner'
    | '-owner'
    | 'defect_count_high'
    | '-defect_count_high'
    | 'defect_count_medium'
    | '-defect_count_medium'
    | 'defect_count_low'
    | '-defect_count_low'
    | 'project_name'
    | '-project_name'
    | 'target_name'
    | '-target_name'
    | 'run_number'
    | '-run_number'
    | 'testcase_pass_percent'
    | '-testcase_pass_percent'
    | 'created_at'
    | '-created_at'
  )[]
  startDate?: string
  status?: ('pending' | 'running' | 'completed' | 'failed' | 'stopped')[]
  targetSlug?: string[]
}
export type GetRunStatsByUserApiResponse = /** status 200 Returns statistics of all runs current_user can view. */ RunCounts
export type GetRunStatsByUserApiArg = {
  userSlug: string
  status?: 'pending' | 'running' | 'completed' | 'failed' | 'stopped'
}
export type GetRunNextStepsApiResponse = /** status 200 Returns Next Steps suggestions for runs.
    Includes lists of critical errors, info updates, and tips
     */ RunNextSteps
export type GetRunNextStepsApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The number of the run for the specified project / target. */
  runNumber: number
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
}
export type GetTestcaseResultsByRunApiResponse = /** status 200 Returns a list of all testcase results.
    This list can be filtered to be only dynamic analysis or regression
     */ TestcaseResultList
export type GetTestcaseResultsByRunApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The number of the run for the specified project / target. */
  runNumber: number
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
  crashesOnly?: boolean
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
  phase?: string
  q?: string
  sortedBy?: (
    | 'status'
    | '-status'
    | 'test_id'
    | '-test_id'
    | 'duration'
    | '-duration'
    | 'seen_at'
    | '-seen_at'
    | 'defect_count'
    | '-defect_count'
    | 'defect_count_high_severity'
    | '-defect_count_high_severity'
    | 'defect_count_medium_severity'
    | '-defect_count_medium_severity'
    | 'defect_count_low_severity'
    | '-defect_count_low_severity'
  )[]
  statusFilters?: ('passing' | 'failing')[]
  typeFilters?: (
    | 'new_test'
    | 'regression_test_fixed'
    | 'regression_test_regressed'
    | 'regression_test_still_failing'
    | 'regression_test_still_passing'
  )[]
}
export type DefectExample = {
  backtrace?: string
  sha256?: string
  created_at?: string
  output?: string
  parsed_backtrace?: {
    [key: string]: any
  }[]
}
export type RunDefectDetails = {
  created_at?: string
  updated_at?: string
  defect_number?: number
  target_slug: string
  title?: string
  cwe_number?: number | null
  from_valgrind?: boolean
  type?: 'crash' | 'valgrind' | 'mapi' | 'zap'
  cwe_type?: string
  cwe_description?: string
  cwe_link?: string
  description?: string
  severity?: number
  severity_level?: 'low' | 'medium' | 'high'
  defect_class?: string
  defect_class_name?: string
  defect_class_short_description?: string
  defect_class_full_description?: string
  jira_issue_id?: string | null
  jira_issue_url?: string | null
  state?: 'open' | 'closed'
  branch?: string | null
  first_seen?: string
  last_seen?: string
  last_seen_run_id?: number
  last_seen_run_number?: number
  in_new_report?: boolean
  in_regression_report?: boolean
  examples?: DefectExample[]
}
export type RunDefectType = {
  cwe_number: number | null
  count: number
}
export type RunDefectList = {
  defects?: RunDefectDetails[]
  count?: number
  types?: RunDefectType[]
}
export type ApiError = {
  message?: string
}
export type ExeReport = {
  created_at?: string
  updated_at?: string
  sha256: string
  run_id: number
  stack_canaries?: boolean
  dep?: boolean | null
  rpath?: boolean | null
  runpath?: boolean | null
  pie?: boolean | null
  relro?: ('no' | 'partial' | 'full' | null) | null
  arch?:
    | (
        | 'x86'
        | 'x86_64'
        | 'armhf'
        | 'armel'
        | 'armeb'
        | 'aarch64'
        | 'aarch64eb'
        | 'mipsel'
        | 'mipseb'
        | 'mips64el'
        | 'mips64eb'
        | 'ppc32eb'
        | 'ppc64el'
        | 'ppc64eb'
        | null
      )
    | null
  platform?: ('linux' | 'macos' | 'windows' | null) | null
  fortify?: boolean | null
  fortified?: number | null
  fortifiable?: number | null
  force_integrity?: boolean | null
  cfg?: boolean | null
  isolation?: boolean | null
  seh?: boolean | null
  can_se?: boolean | null
  can_mfuzz?: boolean | null
  can_libfuzz?: boolean | null
  can_honggfuzz?: boolean | null
  is_afl?: boolean | null
  is_asan?: boolean | null
  is_golang?: boolean | null
  is_rust?: boolean | null
  cmd_name?: string | null
}
export type ExeReports = {
  created_at?: string
  updated_at?: string
  exe_reports?: ExeReport[]
}
export type RunPhase = {
  phase_name?: string
  status?: string
  preparing_at?: string | null
  started_at?: string | null
  finalizing_at?: string | null
  ended_at?: string | null
  failed?: boolean
  stopped?: boolean
  pending_status?: string
  smoketesting_succeeded?: boolean | null
  duration?: number | null
  elapsed_real_time?: number | null
  msg?: string
}
export type RunPhaseCurrent = {
  phase_name?: string
  status?: string
  preparing_at?: string | null
  started_at?: string | null
  finalizing_at?: string | null
  ended_at?: string | null
  failed?: boolean
  stopped?: boolean
  pending_status?: string
  smoketesting_succeeded?: boolean | null
  duration?: number | null
  elapsed_real_time?: number | null
  msg?: string
  name?: string
  description?: string
  value?: number | null
}
export type EndpointCoverageV1Entry = {
  method: string
  path: string
  status?: number
  hits: number
  tags: string[]
}
export type EndpointCoverageV1 = {
  entries: EndpointCoverageV1Entry[]
}
export type EndpointCoverage = {
  V1: EndpointCoverageV1
}
export type EndpointLatencyV1Entry = {
  method: string
  path: string
  buckets: number[]
  hits: number[]
  tags: string[]
}
export type EndpointLatencyV1 = {
  entries: EndpointLatencyV1Entry[]
}
export type EndpointLatency = {
  V1: EndpointLatencyV1
}
export type GetApiRun = {
  created_at?: string
  updated_at?: string
  duration_mode: 'AUTOMATIC' | 'TIME' | 'GOAL'
  spec_source?: 'OPENAPI' | 'HAR_OPENAPI' | 'POSTMANCOLLECTION_OPENAPI' | 'SWAGGERV2_OPENAPI' | 'POSTMANCOLLECTION' | 'REPLAY' | 'UNKNOWN'
  url?: string
  progress_sec: number
  progress_percent: number
  status: 'PENDING' | 'RUNNING' | 'COMPLETE' | 'CANCELLED' | 'FAILED'
  no_defects_endpoint_count?: number
  total_endpoint_count?: number
  unauthed_endpoint_count?: number
  unsuccessful_endpoint_count?: number
  latency_p50_millis?: number
  latency_p90_millis?: number
  latency_p99_millis?: number
  endpoint_coverage?: EndpointCoverage
  endpoint_latency?: EndpointLatency
  sarif_sha?: string
  junit_sha?: string
  html_report_sha?: string
  reports_zip_sha?: string
}
export type RunListing = {
  created_at?: string
  updated_at?: string
  run_id: number
  run_number?: number
  target_id: number
  is_deleted?: boolean
  build_id?: string
  duration?: number | null
  task_id?: string
  tests_run_symexec?: number
  tests_run_mfuzz?: number
  tests_run_libfuzz?: number
  tests_run_honggfuzz?: number
  tests_per_second?: number
  edges_covered?: number
  run_attributes?: {
    [key: string]: any
  } | null
  cli_version?: string | null
  ci_type?: string | null
  ci_url?: string | null
  branch_name?: string | null
  merge_base_branch_name?: string | null
  parent_revision?: string | null
  revision?: string | null
  scm_remote_origin?: string | null
  scm_commit_author?: string | null
  scm_commit_author_email?: string | null
  scm_commit_subject?: string | null
  interface_info?: {
    [key: string]: any
  }
  cputime?: number
  elapsed_real_time?: number | null
  docker_registry_id?: number | null
  docker_pullable?: string
  fetched_docker_image_digest?: string
  base_testsuite_id?: number | null
  user_testsuite_id?: number | null
  output_testsuite_id?: number | null
  is_pure_docker?: boolean
  advanced_triage_run_valgrind?: boolean
  current_workers?: number
  desired_workers?: number
  n_total_memory?: number
  n_total_cpu?: number
  n_desired_memory?: number
  n_desired_cpu?: number
  started_by?: string
  is_mayhem_bot?: boolean
  n_starting_testcases?: number | null
  n_optimized_set_testcases?: number
  docker_registry_url?: string
  phases?: RunPhase[]
  tests_run?: number
  n_testcase_reports?: number
  n_behavior_testcase_reports?: number
  n_regression_testcase_reports?: number
  n_regression_defect_reports?: number
  n_crash_reports?: number
  n_behavior_crash_reports?: number
  n_regression_crash_reports?: number
  n_defects?: number
  n_behavior_defects?: number
  n_regression_defects?: number
  n_valgrind_errors?: number
  n_behavior_valgrind_errors?: number
  n_regression_valgrind_errors?: number
  n_exploitability_factors_passing?: number
  n_exploitability_factors_total?: number
  started_at?: string | null
  ended_at?: string | null
  pending?: boolean
  started?: boolean
  stopped?: boolean
  ended?: boolean
  failed?: boolean
  has_critical_errors?: boolean
  description?: string
  name?: string
  phase?: RunPhaseCurrent
  owner?: string
  owner_name?: string
  target_slug?: string
  target_name?: string
  project_slug?: string
  project_name?: string
  api_run?: GetApiRun
}
export type RunsPage = {
  runs: RunListing[]
  count?: number
  api_count?: number
  code_count?: number
}
export type EventBase = {
  created_at?: string
  updated_at?: string
  event_id?: number
  run_id?: number
  event_level: number
  event_code: number
  event_phase: string
  component: string
  event_desc?: string
  event_attributes?: {
    [key: string]: any
  } | null
  suggestion?: boolean
}
export type RunPhaseFull = {
  phase_name?: string
  status?: string
  preparing_at?: string | null
  started_at?: string | null
  finalizing_at?: string | null
  ended_at?: string | null
  failed?: boolean
  stopped?: boolean
  pending_status?: string
  smoketesting_succeeded?: boolean | null
  duration?: number | null
  elapsed_real_time?: number | null
  msg?: string
  latest_critical_event?: (object | null) | EventBase
}
export type RunFile = {
  sha256: string
  path: string
  extract?: boolean
}
export type RunGet = {
  created_at?: string
  updated_at?: string
  run_id: number
  run_number?: number
  target_id: number
  is_deleted?: boolean
  build_id?: string
  duration?: number | null
  task_id?: string
  tests_run_symexec?: number
  tests_run_mfuzz?: number
  tests_run_libfuzz?: number
  tests_run_honggfuzz?: number
  tests_per_second?: number
  edges_covered?: number
  run_attributes?: {
    [key: string]: any
  } | null
  cli_version?: string | null
  ci_type?: string | null
  ci_url?: string | null
  branch_name?: string | null
  merge_base_branch_name?: string | null
  parent_revision?: string | null
  revision?: string | null
  scm_remote_origin?: string | null
  scm_commit_author?: string | null
  scm_commit_author_email?: string | null
  scm_commit_subject?: string | null
  interface_info?: {
    [key: string]: any
  }
  cputime?: number
  elapsed_real_time?: number | null
  docker_registry_id?: number | null
  docker_pullable?: string
  fetched_docker_image_digest?: string
  base_testsuite_id?: number | null
  user_testsuite_id?: number | null
  output_testsuite_id?: number | null
  is_pure_docker?: boolean
  advanced_triage_run_valgrind?: boolean
  current_workers?: number
  desired_workers?: number
  n_total_memory?: number
  n_total_cpu?: number
  n_desired_memory?: number
  n_desired_cpu?: number
  started_by?: string
  is_mayhem_bot?: boolean
  n_starting_testcases?: number | null
  n_optimized_set_testcases?: number
  docker_registry_url?: string
  phases?: RunPhase[]
  tests_run?: number
  n_testcase_reports?: number
  n_behavior_testcase_reports?: number
  n_regression_testcase_reports?: number
  n_regression_defect_reports?: number
  n_crash_reports?: number
  n_behavior_crash_reports?: number
  n_regression_crash_reports?: number
  n_defects?: number
  n_behavior_defects?: number
  n_regression_defects?: number
  n_valgrind_errors?: number
  n_behavior_valgrind_errors?: number
  n_regression_valgrind_errors?: number
  n_exploitability_factors_passing?: number
  n_exploitability_factors_total?: number
  started_at?: string | null
  ended_at?: string | null
  pending?: boolean
  started?: boolean
  stopped?: boolean
  ended?: boolean
  failed?: boolean
  has_critical_errors?: boolean
  description?: string
  name?: string
  phase?: RunPhaseCurrent
  owner?: string
  owner_name?: string
  target_slug?: string
  target_name?: string
  project_slug?: string
  project_name?: string
  api_run?: GetApiRun
}
export type RunFull = {
  created_at?: string
  updated_at?: string
  run_id: number
  run_number?: number
  target_id: number
  is_deleted?: boolean
  build_id?: string
  duration?: number | null
  task_id?: string
  tests_run_symexec?: number
  tests_run_mfuzz?: number
  tests_run_libfuzz?: number
  tests_run_honggfuzz?: number
  tests_per_second?: number
  edges_covered?: number
  run_attributes?: {
    [key: string]: any
  } | null
  cli_version?: string | null
  ci_type?: string | null
  ci_url?: string | null
  branch_name?: string | null
  merge_base_branch_name?: string | null
  parent_revision?: string | null
  revision?: string | null
  scm_remote_origin?: string | null
  scm_commit_author?: string | null
  scm_commit_author_email?: string | null
  scm_commit_subject?: string | null
  interface_info?: {
    [key: string]: any
  }
  cputime?: number
  elapsed_real_time?: number | null
  docker_registry_id?: number | null
  docker_pullable?: string
  fetched_docker_image_digest?: string
  base_testsuite_id?: number | null
  user_testsuite_id?: number | null
  output_testsuite_id?: number | null
  is_pure_docker?: boolean
  advanced_triage_run_valgrind?: boolean
  current_workers?: number
  desired_workers?: number
  n_total_memory?: number
  n_total_cpu?: number
  n_desired_memory?: number
  n_desired_cpu?: number
  started_by?: string
  is_mayhem_bot?: boolean
  n_starting_testcases?: number | null
  n_optimized_set_testcases?: number
  docker_registry_url?: string
  phases?: RunPhaseFull[]
  tests_run?: number
  n_testcase_reports?: number
  n_behavior_testcase_reports?: number
  n_regression_testcase_reports?: number
  n_regression_defect_reports?: number
  n_crash_reports?: number
  n_behavior_crash_reports?: number
  n_regression_crash_reports?: number
  n_defects?: number
  n_behavior_defects?: number
  n_regression_defects?: number
  n_valgrind_errors?: number
  n_behavior_valgrind_errors?: number
  n_regression_valgrind_errors?: number
  n_exploitability_factors_passing?: number
  n_exploitability_factors_total?: number
  started_at?: string | null
  ended_at?: string | null
  pending?: boolean
  started?: boolean
  stopped?: boolean
  ended?: boolean
  failed?: boolean
  has_critical_errors?: boolean
  description?: string
  name?: string
  phase?: RunPhaseCurrent
  owner?: string
  owner_name?: string
  target_slug?: string
  target_name?: string
  project_slug?: string
  project_name?: string
  api_run?: GetApiRun
  run_files?: RunFile[]
  previous_run?: RunGet
  next_run_number?: number | null
  mayhemfile_flat?: string
  mayhemfile_structured?: {
    [key: string]: any
  }
  env_vars?: {
    [key: string]: any
  }
  overrides?: {
    [key: string]: any
  }
  n_defects_active_high?: number
  n_defects_active_medium?: number
  n_defects_active_low?: number
}
export type PostApiRun = {
  duration_mode?: 'AUTOMATIC' | 'TIME' | 'GOAL'
  spec_source?: 'OPENAPI' | 'HAR_OPENAPI' | 'POSTMANCOLLECTION_OPENAPI' | 'SWAGGERV2_OPENAPI' | 'POSTMANCOLLECTION' | 'REPLAY' | 'UNKNOWN'
  url?: string
  cli_version?: string
  ci_enabled?: boolean
  ci_type?: string
  ci_build_id?: string
  scm_remote_origin?: string
  scm_commit_sha?: string
  scm_parent_sha?: string
  scm_branch?: string
  scm_tag?: string
  scm_pull_request_num?: string
  commit_author?: string
  commit_author_email?: string
  commit_subject?: string
  endpoint_coverage?: EndpointCoverage
  endpoint_latency?: EndpointLatency
}
export type RunPost = {
  created_at?: string
  build_id?: string
  duration?: number | null
  run_attributes?: {
    [key: string]: any
  }
  merge_base_branch_name?: string
  parent_revision?: string
  branch_name?: string
  revision?: string
  ci_url?: string
  cli_version?: string
  ci_type?: string
  scm_remote_origin?: string
  scm_commit_author?: string
  scm_commit_author_email?: string
  scm_commit_subject?: string
  interface_info?: {
    [key: string]: any
  }
  cputime?: number
  elapsed_real_time?: number
  docker_registry_id?: number
  docker_pullable?: string
  fetched_docker_image_digest?: string
  advanced_triage_run_valgrind?: boolean
  current_workers?: number
  desired_workers?: number
  n_total_memory?: number
  n_total_cpu?: number
  n_desired_memory?: number
  n_desired_cpu?: number
  run_files?: RunFile[]
  env_vars?: {
    [key: string]: any
  }
  overrides?: {
    [key: string]: any
  }
  phases?: string[]
  cancel_previous_on_branch?: boolean
  cancel_only_fuzzing?: boolean
  run_type?: 'code' | 'api'
  api_run?: (object | null) | PostApiRun
}
export type Ok = {
  result?: string
}
export type RunEndpointLatencies = {
  P50: number
  P90: number
  P99: number
}
export type RunEndpoint = {
  id: string
  method: string
  path: string
  tags: string[]
  number_of_defects: number
  defects_by_severity: {
    [key: string]: number
  }
  max_severity_level: string
  responses?: {
    [key: string]: number
  }
  latencies: RunEndpointLatencies
  covered: boolean
  has_authentication_failures: boolean
}
export type RunEndpoints = {
  endpoints: RunEndpoint[]
  tags: string[]
}
export type RunStatEntry = {
  count?: number
  timestamp?: string
}
export type RunStat = {
  stats?: RunStatEntry[]
}
export type RunCounts = {
  n_user_total_runs: number
  n_total_runs: number
  n_active_runs: number
  n_active_and_pending_runs: number
}
export type RunNextSteps = {
  errors?: EventBase[]
  info?: EventBase[]
  tips?: {
    [key: string]: any
  }
}
export type TestcaseReportDefect = {
  defect_number?: number
  title?: string
  cwe_number?: number
  severity?: number
  severity_level?: 'low' | 'medium' | 'high'
}
export type TestcaseResult = {
  created_at?: string
  updated_at?: string
  testcase_report_id: number
  testcase_number: number
  run_number: number
  sha256: string
  testcase_source?: string
  repro_cmdline?: string
  output?: string
  crashed?: boolean
  timed_out?: boolean
  is_regression?: boolean
  backtrace?: string
  parsed_backtrace?: {
    [key: string]: any
  }[]
  registers?: string
  disassembly?: string
  att_disassembly?: string
  stack?: string
  maps?: string
  cwe_number?: number
  signal_number?: number
  faulting_address?: string
  crash_type?: string
  defect_hash?: string
  valgrind_completed?: boolean
  valgrind_failed?: boolean | null
  valgrind_failed_msg?: string
  valgrind_errors_truncated?: boolean
  valgrind_timed_out?: boolean
  valgrind_crashed?: boolean
  triage_ok?: boolean
  triage_errors?: string
  execution_time?: number | null
  n_valgrind_errors?: number
  defects?: TestcaseReportDefect[]
  previous_has_defects?: boolean
  n_defects?: number
}
export type TestcaseResultList = {
  testcase_reports?: TestcaseResult[]
  count?: number
  total_count?: number
  regression_count?: number
}
