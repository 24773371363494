import 'core-js/features/array/includes'
import 'core-js/features/object/values'
import 'core-js/proposals/relative-indexing-method'

import { createRoot } from 'react-dom/client'

import { UnknownAction } from 'redux'

import AppContainer from './AppContainer'
import { fetchApiInfo, fetchMayhemInfo } from './features/config/actions'
import store from './redux/store'
import { FEATURE_NOTIFICATION_SETTINGS } from './featureFlags'

store.dispatch(fetchApiInfo() as unknown as UnknownAction)
store.dispatch(fetchMayhemInfo() as unknown as UnknownAction)

const isProduction = import.meta.env.PROD

// Load feature flags if not development
if (!isProduction) {
  if (localStorage.getItem(FEATURE_NOTIFICATION_SETTINGS) === null) {
    localStorage.setItem(FEATURE_NOTIFICATION_SETTINGS, true.toString())
  }
}

const rootElement = document.getElementById('root')
if (!rootElement) {
  throw new Error('Failed to find the root element')
}
const root = createRoot(rootElement)

root.render(<AppContainer />)
