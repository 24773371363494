import { AuthAction, DID_REDIRECT_TO_WORKSPACE_INVITES, HAS_SENT_LOGIN_TRACKING_EVENT, RECEIVE_JWT } from './reduxUtils'

export type AuthState = {
  mdsbomJwtToken: string | undefined
  hasSentLoginTrackingEvent: boolean
  didRedirectToWorkspaceInvites: boolean
}

export default function authReducer(
  state: AuthState = {
    mdsbomJwtToken: undefined,
    hasSentLoginTrackingEvent: false,
    didRedirectToWorkspaceInvites: false
  },
  action: AuthAction
): AuthState {
  switch (action.type) {
    case RECEIVE_JWT:
      return {
        ...state,
        mdsbomJwtToken: action.jwtToken
      }

    case HAS_SENT_LOGIN_TRACKING_EVENT:
      return {
        ...state,
        hasSentLoginTrackingEvent: true
      }
    case DID_REDIRECT_TO_WORKSPACE_INVITES:
      return {
        ...state,
        didRedirectToWorkspaceInvites: true
      }

    default:
      return state
  }
}
