import React, { useEffect } from 'react'

import { Button, Card, CardBody, CardHeader, Divider, HStack, Heading, Stack, Table, TableContainer, Text, Th, Thead, Tr } from '@chakra-ui/react'
import PrintIcon from '@material-design-icons/svg/sharp/print.svg?react'
import { useLocation } from 'wouter'

import { RunDefectRow } from './RunDefectRow'

import ListFooter from '@/components/ListFooter'
import { Page } from '@/components/Page'
import MayhemTableLoader from '@/components/MayhemTableLoader'
import { useGetRunQuery, useGetDefectsByRunQuery } from '@/redux/api/runs'
import { getRunDefectReportPageTitle } from '@/titles'

import { DefectReport } from '@/features/defect-report/DefectReport'
import ProjectPermissionGuard from '@/features/project/ProjectPermissionGuard'
import { MayhemBreadcrumb } from '@/components/MayhemBreadcrumb'

interface Props {
  workspaceSlug: string
  projectSlug: string
  targetSlug: string
  run: string
}

export function RunDefectsReportPage({ workspaceSlug, projectSlug, targetSlug, run }: Props) {
  const [location, setLocation] = useLocation()

  let runNumber: number
  try {
    runNumber = Number.parseInt(run, 10)
  } catch {
    throw new Error(`Invalid run number: ${run}`)
  }

  const { data: runData, isLoading: isRunLoading } = useGetRunQuery({ owner: workspaceSlug, projectSlug, targetSlug, runNumber })
  const { owner_name: workspaceName, project_name: projectName, target_name: targetName } = runData || {}
  const n_defects = runData?.n_defects || 0
  const {
    data: defectsData,
    isLoading,
    isError
  } = useGetDefectsByRunQuery(
    {
      owner: workspaceSlug,
      projectSlug,
      targetSlug,
      runNumber,
      perPage: 50000
    },
    { skip: !runData }
  )

  useEffect(() => {
    if (workspaceName && projectName && targetName) {
      document.title = getRunDefectReportPageTitle(workspaceName, projectName, targetName, runNumber)
    }
  }, [location, workspaceName, projectName, runNumber, setLocation, targetName])

  return (
    <Page
      isLoading={isRunLoading || isLoading}
      header={
        <MayhemBreadcrumb
          isLoading={isRunLoading}
          items={[
            { text: 'Projects', path: `/${workspaceSlug}/-/projects` },
            { text: projectName!, path: `/${workspaceSlug}/${projectSlug}` },
            {
              text: targetName!,
              path: `/${workspaceSlug}/${projectSlug}/${targetSlug}`,
              isBadge: true,
              badgeTooltip: 'Target',
              hideSeparator: true
            },
            {
              text: `Run ${runNumber}`,
              path: `/${workspaceSlug}/${projectSlug}/${targetSlug}/${runNumber}`
            },
            { text: 'Defects Report' }
          ]}
        />
      }
    >
      <Heading>
        <HStack gap={8} marginBottom={8}>
          <Text>Defects</Text>
          <Button
            leftIcon={<PrintIcon />}
            onClick={() => window.print()}
            css={{
              '@media print': {
                display: 'none'
              }
            }}
          >
            Print
          </Button>
        </HStack>
      </Heading>

      {isLoading ? (
        <MayhemTableLoader />
      ) : (
        <ProjectPermissionGuard workspaceSlug={workspaceSlug} projectSlug={projectSlug}>
          <React.Fragment>
            <Card>
              <CardHeader>
                <Heading variant="cardHeading">Overview</Heading>
              </CardHeader>
              <CardBody p={8}>
                <Stack gap={0}>
                  <Text>
                    {n_defects} defect{n_defects !== 1 && 's'} found in this run.&nbsp;
                  </Text>
                  <TableContainer id="defect-list">
                    <Table>
                      <Thead>
                        <Tr>
                          <Th width="50%" textAlign="left">
                            Defect
                          </Th>
                          <Th width="50%" textAlign="right">
                            Example Test Case
                          </Th>
                        </Tr>
                      </Thead>
                    </Table>
                  </TableContainer>
                  {defectsData?.defects?.map((defect) => (
                    <RunDefectRow
                      key={defect.defect_number}
                      workspaceSlug={workspaceSlug}
                      projectSlug={projectSlug}
                      targetSlug={targetSlug}
                      runNumber={runNumber}
                      defect={defect}
                      printable
                    />
                  ))}
                  <ListFooter isLoading={isLoading} nItems={defectsData?.defects?.length || 0} itemName="defect" isFail={isError} mini />
                </Stack>
              </CardBody>
            </Card>
            {n_defects > 0 && (
              <React.Fragment>
                <Divider my="8" />
                <Heading size="xl" marginBottom={4}>
                  Defect Details
                </Heading>
                {defectsData?.defects?.map((defect) => (
                  <DefectReport
                    key={defect.defect_number}
                    workspaceSlug={workspaceSlug}
                    projectSlug={projectSlug}
                    targetSlug={targetSlug}
                    runNumber={runNumber}
                    defect={defect}
                  />
                ))}
              </React.Fragment>
            )}
          </React.Fragment>
        </ProjectPermissionGuard>
      )}
    </Page>
  )
}
